import { createRouter, createWebHistory } from '@ionic/vue-router';
import { store } from '@/store';
import Tabs from '@/views/Tabs.vue'

const routes = [
  {
    path: '/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/splash'
      },
      {
        path: 'splash',
        component: () => import('@/views/Splash.vue'),
      },
      {
        path: 'home',
        component: () => import('@/views/Home/Home.vue'),
      },
      {
        path: 'home/section/:section',
        component: () => import('@/views/Home/Section.vue'),
      },
      {
        path: 'home/subsection/:section',
        component: () => import('@/views/Home/Subsection.vue'),
      },
      {
        path: 'home/detail/:section/:id',
        component: () => import('@/views/Home/SubsectionDetail.vue'),
      },
      {
        path: 'home/detail/:id',
        component: () => import('@/views/Home/SubsectionDetail.vue'),
      },
      {
        path: 'resources',
        component: () => import('@/views/Resources/Resources.vue'),
      },
      {
        path: 'resources/:id',
        component: () => import('@/views/Resources/ResourcesDetail.vue')
      },
      {
        path: 'relax',
        component: () => import('@/views/Relax/Relax.vue'),
      },
      {
        path: 'relax/:id',
        component: () => import('@/views/Relax/RelaxDetail.vue')
      },
      {
        path: 'settings',
        component: () => import('@/views/Settings/Settings.vue')
      }
    ]
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  await store.getAppData();
  await store.updateBackButtonStatus(to, from);
  await store.updateItem('isSearching', false);
  const lang = await store.getLanguage();
  // TODO: Need to make this dynamic somehow (will break when translations are updated)
  // Maybe just use the existing resources items??
  if (to.path.startsWith('/home/detail/crisis-hotlines-')) {
    let id = to.path.split('/').pop();
    return next({ 
      path: '/home/subsection/crisis-hotlines-'+lang,
      hash: `#item${id}`
    });
  }
  return next();
})

router.afterEach(async (to) => {
  // Send a page view to analytics
  if (window.ga) {
    const pageName = to.fullPath;
    window.ga('set', 'page', pageName);
    window.ga('send', 'pageview');
  }
  // Scroll hash into view
  const el = window.location.href.split('#')[1]
  if (el?.length) {
    setTimeout(() => {
      document.getElementById(el)?.scrollIntoView({
        behavior: "smooth",
      });
      const expandEvent = new CustomEvent('expandparent', { detail: { id: el.replace('item', '') } });
      document.dispatchEvent(expandEvent);
    }, 500);
  }
});

export default router
